<template>
  <div class="main">
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>设备配置</el-breadcrumb-item>
      <el-breadcrumb-item>摄像头配置</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-card
      shadow="hover"
      class="card-sty"
      body-style="height:100%;display: flex;flex-direction: column;justify-content: space-between;"
    >
        <div v-auth="'cameraDrive-add'">
          <el-button type="primary" size="small" @click="addCameraDrive">新增摄像头</el-button>
          &nbsp;
        </div>
            <el-table
        style="margin-top: 0; margin-bottom: 0; flex: 1;margin-top: 14px;"
        height="100%"
        header-row-class-name="my-table-head-style"
                v-loading="spinning"
                :data="data"
                row-key="id"
            >
              <el-table-column
                  prop="cameraName"
                  label="摄像头名字">
              </el-table-column>
              <el-table-column
                  prop="cameraNetworkDvrSerialNumber"
                  label="录像机序列号">
              </el-table-column>
              <el-table-column
                  prop="informationId"
                  label="录像机通道">
              </el-table-column>
              <el-table-column
                  prop="farmAreaName"
                  label="农场基地名称">
              </el-table-column>
              <!--<el-table-column
                  prop="farmParcelName"
                  label="农场地块名称">
              </el-table-column>-->
              <el-table-column
                  fixed="right"
                  width="200"
                  label="操作"
                  align="center"
                  v-if="authVerify.verify('cameraDrive-edit,cameraDrive-delete')"
              >
                <template slot-scope="scope">
                  <el-button icon="el-icon-edit-outline" size="mini" v-auth="'cameraDrive-edit'"
                             @click="editCameraDriveData(scope.row)">编辑
                  </el-button>
                  <el-button icon="el-icon-delete" size="mini" v-auth="'cameraDrive-delete'"
                             @click="deleteCameraDriveData(scope.row)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
                layout="total,sizes, prev, pager, next"
                :page-sizes="[10,20,30,40]"
                :current-page="currentPage"
                :page-size="pageSize"
                @current-change="handlePageChange"
                @size-change="handleSizeChange"
                :total="totalCount">
            </el-pagination>
      </el-card>
    <!-- 添加 -->
    <template>
      <div>
        <a-drawer
            title="新增摄像头"
            width="400"
            placement="right"
            :closable="false"
            :visible="addCameraDriveVisible"
            @close="addCameraDriveVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model :model="CameraDriveFormValue" ref="cameraDriveRefFormAdd"
                          :rules="CameraDriveRules" :label-col="{ span: 6 }"
                          :wrapper-col="{ span: 17 }">
              <a-form-model-item label="摄像头名称" prop="cameraName">
                <a-input
                    v-model="CameraDriveFormValue.cameraName"
                />
              </a-form-model-item>
              <a-form-model-item label="所属录像机" prop="cameraNetworkDvrId">
                <a-select  style="width: 100%" placeholder="请选择" v-model="CameraDriveFormValue.cameraNetworkDvrId">
                  <a-select-option v-for="(i,index) in dvrData" :key="i.id">
                    {{ i.serialNumber }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="录像机通道" prop="informationId">
                <a-select  style="width: 100%" placeholder="请选择" v-model="CameraDriveFormValue.informationId">
                  <a-select-option v-for="(i,index) in 32" :key="i">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="所属基地" prop="farmAreaId">
                <a-select v-model="CameraDriveFormValue.farmAreaId">
                  <a-select-option v-for="obj in farmAreaList" :key="obj.id">
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
              :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="addCameraDriveVisible = false">
              取消
            </a-button>
            <a-button type="primary" @click="toAddCameraDrive">
              确认
            </a-button>
          </div>

        </a-drawer>
      </div>
    </template>
    <!-- 编辑 -->
    <template>
      <div>
        <a-drawer
            title="编辑摄像头"
            width="400"
            placement="right"
            :closable="false"
            :visible="editCameraDriveVisible"
            @close="editCameraDriveVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model :model="CameraDriveFormValue" ref="cameraDriveRefFormEdit"
                          :rules="CameraDriveRules" :label-col="{ span: 6 }"
                          :wrapper-col="{ span: 17 }">
              <a-form-model-item label="摄像头名称" prop="cameraName">
                <a-input
                    v-model="CameraDriveFormValue.cameraName"
                />
              </a-form-model-item>
              <a-form-model-item label="所属录像机" prop="cameraNetworkDvrId">
                <a-select  style="width: 100%" placeholder="请选择" v-model="CameraDriveFormValue.cameraNetworkDvrId">
                  <a-select-option v-for="(i,index) in dvrData" :key="i.id">
                    {{ i.serialNumber }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="录像机通道" prop="informationId">
                <a-select  style="width: 100%" placeholder="请选择" v-model="CameraDriveFormValue.informationId">
                  <a-select-option v-for="(i,index) in 32" :key="i">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="所属基地" prop="farmAreaId">
                <a-select v-model="CameraDriveFormValue.farmAreaId">
                  <a-select-option v-for="obj in farmAreaList" :key="obj.id">
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
              :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="editCameraDriveVisible = false">
              取消
            </a-button>
            <a-button type="primary" @click="toEditCameraDrive">
              确认
            </a-button>
          </div>

        </a-drawer>
      </div>
    </template>
  </div>
</template>

<script>
    export default {
        data() {
            return {
                currentPage: 1,
                pageSize: 10,
                totalCount: 0,
                CameraDriveFormValue: {
                    id: '',
                    cameraName: '',
                    cameraNetworkDvrId: undefined,
                    informationId: undefined,
                    farmAreaId: undefined,
                    farmParcelId: undefined,
                },
                CameraDriveRules: {
                    cameraNetworkDvrId: [
                        {required: true, message: '请选择绑定的摄像机!', trigger: 'blur'},
                    ], informationId: [
                        {required: true, message: '请选择通道!', trigger: 'blur'},
                    ], cameraName: [
                        {required: true, message: '请输入摄像头名称!', trigger: 'blur'},
                    ], farmAreaId: [
                        {required: true, message: '请选择基地!', trigger: 'blur'},
                    ],
                },
                spinning: true,
                screenWidth: document.documentElement.clientWidth,//屏幕宽度
                screenHeight: document.documentElement.clientHeight - 50,//屏幕高度
                addCameraDriveVisible: false,
                editCameraDriveVisible: false,
                data: [],
                dvrData: [],
                farmAreaList: [],
            }
        },
        watch: {
            'screenWidth': function (val) { //监听屏幕宽度变化
                console.log(val);
            },
            'screenHeight': function (val) { //监听屏幕高度变化
                console.log(val);
            },
        },
        mounted() {
            const _this = this;
            window.onresize = function () { // 定义窗口大小变更通知事件
                _this.screenWidth = document.documentElement.clientWidth; //窗口宽度
                _this.screenHeight = document.documentElement.clientHeight - 50; //窗口高度
            };
        },
        created() {
            this.getCameraDriveData();
            this.getCameraDvrData();
            this.getFarmAreaList();
        },
        methods: {
            getFarmAreaList() {
                this.http.farmArea.getFarmAreaList().then(response => {
                        if (response.data.status == 200) {
                            this.farmAreaList = response.data.data;
                        } else {
                            this.$message.warning('数据获取失败');
                        }
                    });
            },
            getCameraDvrData() {
                this.http.camera.getCameraNetworkDvrList()
                    .then(response => {
                        if (response.data.status === 200) {
                            this.spinning = false;
                            this.dvrData = response.data.data;
                        } else {
                            this.$message.warning('数据获取失败');
                        }
                    });
            },
            getCameraDriveData() {
                this.http.camera.getCameraDriveListPage({
                    size: this.pageSize,
                    current: this.currentPage,
                })
                    .then(response => {
                        if (response.data.status === 200) {
                            this.spinning = false;
                            this.data = response.data.data.records;
                            this.totalCount = response.data.data.total;
                            this.currentPage = response.data.data.current;
                        } else {
                            this.$message.warning('数据获取失败');
                        }
                    });
            },
            deleteCameraDriveData(key) {
                let self = this;
                this.$confirm({
                    title: '确定删除吗?',
                    content: '',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: () => {
                        this.http.camera.deleteCameraDrive({
                            id: key.id
                        })
                            .then(response => {
                                if (response.data.status === 200) {
                                    this.$message.success(response.data.msg);
                                    self.getCameraDriveData();
                                } else {
                                    this.$message.error(response.data.msg);
                                }
                            })
                    },
                    onCancel() {
                    },
                });
            },

            addCameraDrive() {
                this.cleanDTU();
                this.addCameraDriveVisible = true;
                  this.$refs.cameraDriveRefFormAdd.resetFields()
            },
            toAddCameraDrive() {
                this.$refs.cameraDriveRefFormAdd.validate(valid => {
                    if (valid) {
                        console.log(this.CameraDriveFormValue);
                        this.http.camera.createCameraDrive(this.CameraDriveFormValue).then(response => {
                            if (response.data.status === 200) {
                                this.$message.success(response.data.msg);
                                this.addCameraDriveVisible = false;
                            } else {
                                this.$message.warning(response.data.msg);
                            }
                            this.getCameraDriveData();
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            editCameraDriveData(record) {
                console.log(record);
                this.cleanDTU();
                this.CameraDriveFormValue = {
                    id: record.id,
                    cameraNetworkDvrId: record.cameraNetworkDvrId,
                    informationId: record.informationId,
                    cameraName: record.cameraName,
                    farmAreaId: record.farmAreaId,
                    farmParcelId: record.farmParcelId,
                };
                this.editCameraDriveVisible = true;
                  this.$refs.cameraDriveRefFormEdit.resetFields()
            },
            cleanDTU() {
                this.CameraDriveFormValue = {
                    id: '',
                    cameraName: '',
                    cameraNetworkDvrId: undefined,
                    informationId: undefined,
                    farmAreaId: undefined,
                    farmParcelId: undefined,
                };
            },

            toEditCameraDrive() {
                this.$refs.cameraDriveRefFormEdit.validate(valid => {
                    if (valid) {
                        this.http.camera.updateCameraDrive(this.CameraDriveFormValue)
                            .then(response => {
                                if (response.data.status === 200) {
                                    this.$message.success(response.data.msg);
                                    this.editCameraDriveVisible = false;
                                } else {
                                    this.$message.warning(response.data.msg);
                                }
                                this.getCameraDriveData();
                            })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            handlePageChange(val) {
                this.spinning = true;
                this.currentPage = val;
                this.getCameraDriveData();
            },
            handleSizeChange(val) {
                this.spinning = true;
                this.pageSize = val;
                this.getCameraDriveData();
            },

        },
    }
</script>

<style scoped>
  /*滚动条整体样式*/
  .scroll_bar::-webkit-scrollbar {
    width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
  }

  .scroll_bar::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background-color: #cecece;
  }

  .scroll_bar::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #ededed;
  }

  .demo-table-expand >>> .el-form-item__label {
    color: #99a9bf;
    font-size: 8px;
  }

  .demo-table-expand >>> .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
  }
}
</style>
